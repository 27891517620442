@import '../../../styles/customMediaQueries.css';

.root {
  margin-top: 20px;
}

.certificatesBtn {
  display: flex;
  align-items: center;

  color: var(--linksColor);
  font-weight: var(--fontWeightSemiBold);
  text-decoration: underline;
  cursor: pointer;

  & svg {
    margin-right: 10px;
  }
}

.certificatesHolder {
}

.certificatesCarousel {
  max-width: 524px;
  margin: 0 0 40px;
  padding: 20px 48px;
  background-color: var(--backgroundGray);
  border-radius: 8px;

  & :global(.alice-carousel),
  & :global(.alice-carousel) > div:first-of-type,
  & :global(.alice-carousel__wrapper) {
    position: relative;
    height: 100%;
  }

  & :global(.alice-carousel) > div:first-of-type {
    z-index: 1;
  }

  & :global(.alice-carousel__prev-btn),
  & :global(.alice-carousel__next-btn) {
    position: absolute;
    width: auto;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    cursor: pointer;
    z-index: 9;
    
    
    & button {
      border: none;
      padding: 0;
      cursor: pointer;
    }
  }

  & :global(.alice-carousel__prev-btn) {
    left: -50px;
  }

  & :global(.alice-carousel__next-btn) {
    right: -50px;
  }

  & :global(.alice-carousel__slide-info) {
    display: block;
    position: static;
    margin: 20px 0 0;
    background-color: transparent;
    color: #839c96;
    text-align: center;
  }

  & :global(.alice-carousel__slide-info .alice-carousel__slide-info-item:first-of-type) {
    color: var(--colorBlack);
  }
}

.certificatesListItem {
  max-width: 429px;
  max-height: 304px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.certificatesPopupBtn {
  max-width: 162px;
  min-height: 38px;
  margin: 0 auto;
  border-radius: 23px;
  font-weight: var(--fontWeightLight);
}