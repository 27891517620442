@import '../../../styles/customMediaQueries.css';

.root {
}

.content {

}

.avatar {
  flex-shrink: 0;
  margin: 0 0 24px 0;
  width: 140px;
  height: 140px;

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
  }
}

.info {
  flex: 1;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
  color: var(--linksColor);
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 20px 0;
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--linksColor);
}

.headingRow {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0 18px;
  font-weight: var(--fontWeightBold);
  font-size: 28px;
  line-height: 1.15;
  letter-spacing: -0.5px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    font-size: 38px;
  }
}

.editProfile {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  color: var(--linksColor);

  @media (--viewportMedium) {
    display: inline;
  }
}

.contact {
  composes: buttonDefault from global;
  background-color: var(--marketplaceGreen);
  border-color: var(--marketplaceSecondaryColor);
  color: var(--colorWhite);
  min-height: 46px;
  padding: 10px 0;
  margin: 24px 0 0;
  max-width: 309px;

  &:hover,
  &:focus {
    background-color: var(--marketplaceDarkGreen);
  }
}

.languages {
  padding: 0;
  margin: 0 0 10px;
}

.languagesItems {
  text-transform: capitalize;
  font-weight: var(--fontWeightRegular);
}

.address {
  padding: 0;
  margin: 0 0 15px;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.5px;
  color: var(--marketplaceBlue);
  font-weight: var(--fontWeightMedium);

  & svg {
    margin-right: 5px;
  }
}

.certificatesList {
  margin: 0 0 15px;
}

.certificatesListItems {

}
